import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Col, Row, Table } from "reactstrap"
import moment from "moment"
import UserConfirmationModal from "components/Common/UserConfirmationModal"
import { useLoading } from "helpers/custom_hooks"
import { DeleteEntry } from "helpers/backend_helper"
import AppointmentModal from "./AppointmentModal"
import { isTimeMidnight } from "helpers/misc_helpers"

const Appointments = ({ entry, loadData }) => {
  const [entryModal, setEntryModal] = useState(false)
  const [deleteModdal, setDeleteModal] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState()
  const setLoading = useLoading()

  const handleDelete = async () => {
    setLoading(true)
    await DeleteEntry("/appointments/" + selectedEntry.id)
    setDeleteModal(false)
    loadData()
  }

  const getIconFromType = type => {
    if (type == "Lieferung") return "mdi mdi-truck-check"

    if (type == "Reinigung") return "mdi mdi-cup-water"
    if (type == "Abholung") return "mdi mdi-truck-delivery"
  }

  const GetAppointmentColor = element => {
    if (element.completed == 1) return "success"

    if (element.active == 0) return "dark"

    if (moment(element.date).isBefore(moment())) return "danger"

    return "warning"
  }

  return (
    <div>
      <Row className="mt-3">
        <Col>
          <h4 className="card-title mb-2">Termine</h4>
        </Col>
        <Col className="text-end">
          <button
            className="btn btn-primary btn-sm"
            onClick={_ => {
              setSelectedEntry()
              setEntryModal(true)
            }}
          >
            hinzufügen
          </button>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table className="table table-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col">Termin</th>
              <th scope="col">Anlage</th>
              <th scope="col">Typ</th>
              <th scope="col">Mitarbeiter</th>
              <th scope="col">Notiz</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {entry?.appointments?.map((element, key) => (
              <tr
                key={key}
                className={element?.active == 0 ? "text-muted bg-light" : ""}
              >
                <th scope="row">
                  <div className="d-flex align-items-center">
                    <div className="avatar-xs me-3">
                      <span
                        className={
                          "avatar-title rounded-circle bg-soft bg-" +
                          GetAppointmentColor(element) +
                          " text-dark font-size-18"
                        }
                      >
                        <i className={getIconFromType(element.type)} />
                      </span>
                    </div>
                    <span>
                      <Link to={`/termine/${element.id}`}>
                        {" "}
                        {moment(element.date).format(
                          isTimeMidnight(element.date)
                            ? "DD.MM.YYYY"
                            : "DD.MM.YYYY HH:mm"
                        )}
                      </Link>
                    </span>
                  </div>
                </th>
                <td>
                  <div className="text-muted">{element.facility_name}</div>
                </td>
                <td>
                  <div className="text-muted">{element.type}</div>
                </td>
                <td>
                  <div className="text-muted">{element.user_name}</div>
                </td>
                <td>
                  <div className="text-muted">{element.note}</div>
                </td>
                <td style={{ width: "120px" }}>
                  <div className="d-flex justify-content-end gap-3">
                    <Link className="text-success" to="#">
                      <i
                        className="mdi mdi-pencil font-size-18"
                        id="edittooltip"
                        onClick={() => {
                          setSelectedEntry(element)
                          setEntryModal(true)
                        }}
                      ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => {
                          setSelectedEntry(element)
                          setDeleteModal(true)
                        }}
                      ></i>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
            {entry?.bookings?.length == 0 && (
              <tr>
                <td colSpan={10}>
                  <h6 className="text-center mt-3">Keine Termine vorhanden</h6>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {entryModal && (
        <AppointmentModal
          modal={entryModal}
          onSuccess={_ => {
            setEntryModal(false)
            loadData()
          }}
          onClose={_ => setEntryModal(false)}
          selectedEntry={selectedEntry}
          location={entry}
        />
      )}

      <UserConfirmationModal
        message="Möchten Sie diesen Termin wirklich löschen?"
        onCancel={_ => setDeleteModal(false)}
        show={deleteModdal}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export default Appointments
