import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import Bookings from "./Bookings"
import Appointments from "./Appointments"

const DetailsSection = ({ entry, loadData }) => {
  const [modal, setModal] = useState()
  const notifications = [
    { date: "10.10.2023", desc: "Wartung 1 x Cube Standard" },
    { date: "16.10.2023", desc: "Wartung 1 x Cube Standard " },
    { date: "31.10.2023", desc: "Abholung 1 x Cube Standard" },
  ]

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardBody>
            <Bookings entry={entry} loadData={loadData} />
          </CardBody>
          <CardBody>
            <Appointments entry={entry} loadData={loadData} />
          </CardBody>
        </Card>
        <Modal isOpen={modal} toggle={_ => setModal(false)}>
          <ModalHeader toggle={_ => setModal(false)} tag="h4"></ModalHeader>
          <ModalBody>
            <div className="w-100 h-50">
              <h4>Termin abschließen</h4>

              <Label className="form-label mt-4 ">Notizen: </Label>
              <textarea className="form-control" />
            </div>
            <button className="btn btn-success d-block ms-auto mt-2">
              Speichern
            </button>
          </ModalBody>
        </Modal>
      </Col>
    </React.Fragment>
  )
}

export default DetailsSection
