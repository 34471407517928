import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/Layout"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import { useLoadScript } from "@react-google-maps/api"
import "./App.css"

export const LoadingContext = React.createContext(null)
const libraries = ["places"]
const App = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCkS0l56SCELQWiWZP78DdjQ2zlb6WTIy4",
    libraries,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading === true || !isLoaded) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [loading, isLoaded])

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
        {isLoaded && (
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            ))}
          </Routes>
        )}
      </React.Fragment>
    </LoadingContext.Provider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
