import {
  CreateEntry,
  GetUsersByName,
  UpdateEntry,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const AreaModal = ({ modal, toggle, selectedEntries }) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})

  const handleSave = async () => {
    setLoading(true)

    selectedEntries.forEach(entry => {
      entry.date = reqObj.date
      entry.user_id = reqObj.user_id
    })

    await UpdateEntry("/appointments/bulkupdate", selectedEntries)
    toggle()
  }

  useEffect(() => {
    const selectedEntry = selectedEntries[0]
    if (selectedEntry)
      setReqObj({
        name:
          selectedEntries?.length > 1
            ? selectedEntry?.area_name
            : selectedEntry?.location_name,
        location_id: location?.id,
        date: selectedEntry?.date,
        user_id: selectedEntry?.user_id,
        user_name: selectedEntry?.user_name,
      })
  }, [selectedEntries])

  const loadUsers = async inputValue => {
    const results = await GetUsersByName(inputValue)

    return results.map(entry => ({
      value: entry.id,
      label: entry.name,
    }))
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {selectedEntries?.length > 1
          ? "Bereich bearbeiten"
          : "Termin bearbeiten"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Datum Termin:</Label>

                <input
                  type="date"
                  className="form-control"
                  value={moment(reqObj?.date).format("YYYY-MM-DD")}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      date: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Mitarbeiter:</Label>
                <AsyncSelect
                  loadOptions={loadUsers}
                  className="select2-selection w-100"
                  value={{
                    value: reqObj?.user_id,
                    label: reqObj?.user_name,
                  }}
                  onChange={selectedOption => {
                    setReqObj({
                      ...reqObj,
                      user_id: selectedOption.value,
                      user_name: selectedOption.label,
                    })
                  }}
                />
              </div>
            </Col>
          </Row>
          {selectedEntries && (
            <Row>
              <h4>Termine: </h4>
              <ul className="px-4">
                {selectedEntries.map((entry, index) => (
                  <li key={index}>
                    <Link
                      to={"/standorte/" + entry?.location_id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {entry?.location_name} - {entry?.type}
                    </Link>
                  </li>
                ))}
              </ul>
            </Row>
          )}
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AreaModal
