import moment from "moment"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import GoogleMapComp from "./GoogleMap"
import LocationEditModal from "./LocationEditModal"
import DeleteModal from "components/Common/DeleteModal"
import { useLoading } from "helpers/custom_hooks"
import { DeleteEntry } from "helpers/backend_helper"

const Overview = ({ entry, loadData }) => {
  const setLoading = useLoading()
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const navigate = useNavigate()

  const handleDelete = async () => {
    setLoading(true)
    await DeleteEntry("/locations/" + entry.id)
    setDeleteModal(false)
    navigate("/standorte")
  }

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold mb-3">Übersicht</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Name: </th>
                    <td scope="col">{entry?.name ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="col">Kunde: </th>
                    <td scope="col">
                      <Link to={"/kunden/" + entry?.customer_id}>
                        {entry?.customer_name}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Adresse: </th>
                    <td scope="col">
                      <a
                        href={`https://maps.google.com/?q=${entry?.latitude},${entry?.longitude}`}
                        target="_blank"
                        rel="noreferrer"
                        disabled={!entry?.latitude || !entry?.longitude}
                      >
                        {entry?.address}
                        <br /> {entry?.postal_code} {entry?.city}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Bereich: </th>
                    <td scope="col">{entry?.area_name ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="col">Kostenstelle: </th>
                    <td scope="col">{entry?.cost_center ?? "-"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Telefonnummer:</th>
                    <td>
                      <a
                        href={
                          "tel:" +
                          (entry?.phone_number || entry?.customer_phone_number)
                        }
                      >
                        {entry?.phone_number || entry?.customer_phone_number}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">E-Mail:</th>
                    <td>
                      <a href={"mailto:" + entry?.customer_email}>
                        {entry?.customer_email}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Lieferung am:</th>
                    <td>
                      {entry?.delivery_date
                        ? moment(entry?.delivery_date).format("DD.MM.YYYY")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Abholung am:</th>
                    <td>
                      {entry?.pickup_date
                        ? moment(entry?.pickup_date).format("DD.MM.YYYY")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Status: </th>
                    <td scope="col">{entry?.status}</td>
                  </tr>
                  <tr>
                    <th scope="col">Notiz: </th>
                    <td scope="col">{entry?.note}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="hstack gap-2">
              <button
                className="btn btn-soft-danger w-100"
                onClick={_ => setDeleteModal(true)}
              >
                löschen
              </button>
              <button
                className="btn btn-soft-primary w-100"
                onClick={_ => setEditModal(true)}
              >
                bearbeiten
              </button>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {(!entry?.latitude || !entry?.longitude) && (
              <h6 className="text-center mt-3">
                Addressinformation nicht vorhanden!
              </h6>
            )}
            {entry?.latitude && entry?.longitude && (
              <GoogleMapComp lat={entry?.latitude} lng={entry?.longitude} />
            )}
          </CardBody>
        </Card>
      </Col>
      {editModal && (
        <LocationEditModal
          entry={entry}
          modal={editModal}
          toggle={_ => {
            setEditModal(false)
            loadData()
          }}
        />
      )}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
    </React.Fragment>
  )
}

export default Overview
