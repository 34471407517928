import React from "react"
import { getAppointmentTypeColor, isTimeMidnight } from "helpers/misc_helpers"
import moment from "moment"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

export const columns = [
  {
    dataField: "id",
    text: "Nummer",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: { textAlign: "center" },
    formatter: col => <>{col}</>,
  },
  {
    dataField: "address",
    text: "Addresse",
    sort: true,
    formatter: (_, element) => (
      <span>
        <Link to={"/termine/" + element.id}>
          <strong>AG.:</strong> {element.customer_name}
        </Link>
        <br />
        <strong>BV.:</strong> {element.location_name}
        <br />
        {element.address}
        <br />
        {element.postal_code} {element.city}
      </span>
    ),
  },
  {
    dataField: "type",
    text: "Typ",
    sort: true,
    formatter: col => (
      <Badge className="py-2 px-2" color={getAppointmentTypeColor(col)}>
        {col}
      </Badge>
    ),
  },
  {
    dataField: "facility_name",
    text: "Anlage",
    sort: true,
  },
  {
    dataField: "date",
    text: "Datum",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: (_, element) => (
      <>{moment(element?.date).format("DD.MM.YYYY")}</>
    ),
  },
  {
    dataField: "time",
    text: "Uhrzeit",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: (_, element) => (
      <>
        {isTimeMidnight(element?.date)
          ? "-"
          : moment(element?.date).format("HH:mm")}
      </>
    ),
  },
  {
    dataField: "area_name",
    text: "Bereich",
    sort: true,
  },
  {
    dataField: "user_name",
    text: "Mitarbeiter",
    sort: true,
    formatter: col => (
      <>
        {col ? (
          col
        ) : (
          <Badge className="py-2 px-2" color="danger">
            KEIN Mitarbeiter
          </Badge>
        )}
      </>
    ),
  },
  {
    dataField: "test",
    text: "",
    sort: false,
    formatter: (_, element) => (
      <span>
        <Link className="btn btn-primary" to={"/termine/" + element.id}>
          Öffnen
        </Link>
      </span>
    ),
  },
]
